import 'bootstrap';

import {createApp} from 'vue/dist/vue.esm-bundler.js';
import DoorSearch from "./Components/DoorSearch.vue";

import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

import leaflet from 'leaflet';
window.leaflet = leaflet;

import 'leaflet/dist/leaflet.css'

import videojs from 'video.js';
window.videojs = videojs;


import Masonry from 'masonry-layout';
window.Masonry = Masonry;

const app = createApp({});

app.component('door-search', DoorSearch);

app.mount('#app')

const elScrollable = document.querySelector("html")
const elNav = document.querySelector("#scrollTop");

if(elNav) {
    const handleNav = () => {
        const viewportHeight = window.innerHeight;
        const scrollTop = elScrollable.scrollTop;
        elNav.classList.toggle("d-block", scrollTop >= viewportHeight);
    };

    addEventListener("scroll", handleNav)
    addEventListener("resize", handleNav)
    handleNav();
}


// Variants Slider

const swiperVariants = new Swiper('.swiper-variants', {
    spaceBetween: 24,
    slidesPerView: 3,

    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
        768: {
          slidesPerView: 4,
        }
      }
  });


// Start Slider

const swiperDoors = new Swiper('.swiper-doors', {
  slidesPerView: 3,
  centeredSlides: true,
  loop: true,
  
  autoplay: {
    delay: 3500,
  },

  navigation: {
    nextEl: '.door-next',
    prevEl: '.door-prev',
  },

  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },

  breakpoints: {
    768: {
      slidesPerView: 5,
    },
    1200: {
      slidesPerView: 7,
    }
  }
});

// Variants Slider

const swiperAbout = new Swiper('.swiper-about', {
    spaceBetween: 24,
    slidesPerView: 1.2,
    autoplay: true,

    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },

    breakpoints: {
      768: {
        slidesPerView: 1.5,
      },
      1200: {
        slidesPerView: 2.5,
      }
    }
  });

// Misc Slider

  const swiperMisc = new Swiper('.swiper-misc', {
    spaceBetween: 24,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    roundLengths: true,

    breakpoints: {
      992: {
        slidesPerView: 2,
      }
    },

    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
  });


